var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.giftCertificates && _vm.giftCertificates.length > 0
    ? _c(
        "v-container",
        [
          _c("h4", { staticClass: "my-4" }, [
            _vm._v("Scegli i prodotti a cui applicare i tuoi bolloni")
          ]),
          _vm._l(_vm.giftCertificates, function(giftCertificate) {
            return _c(
              "div",
              { key: giftCertificate.giftCertificateId },
              [
                _vm._l(giftCertificate.userGiftCertificates, function(
                  userGiftCertificate,
                  index
                ) {
                  return _c(
                    "v-row",
                    { key: userGiftCertificate.userGiftCertificateId },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("img", {
                          attrs: { src: giftCertificate.image, height: "48px" }
                        })
                      ]),
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              items: giftCertificate.cartItems,
                              label: "Seleziona il prodotto",
                              "item-value": "cartItemId",
                              "item-text": "product.name",
                              "item-disabled": item =>
                                _vm.itemDisabled(
                                  item,
                                  userGiftCertificate,
                                  index
                                ),
                              "return-object": "",
                              dense: ""
                            },
                            on: {
                              change: item =>
                                _vm.addGift(userGiftCertificate, item, index)
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function({ item }) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "selectable-row",
                                          staticStyle: {
                                            width: "100%",
                                            height: "100%"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.product.name) +
                                                    " "
                                                )
                                              ]),
                                              _c("v-list-item-subtitle", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.product.shortDescr
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: userGiftCertificate.cartItem,
                              callback: function($$v) {
                                _vm.$set(userGiftCertificate, "cartItem", $$v)
                              },
                              expression: "userGiftCertificate.cartItem"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                _c(
                  "div",
                  { staticClass: "d-flex justify-end" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary", loading: _vm.loading },
                        on: { click: _vm.apply }
                      },
                      [_vm._v("Applica")]
                    )
                  ],
                  1
                )
              ],
              2
            )
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }