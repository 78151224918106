var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.title
        ? _c(
            "h2",
            {
              staticClass:
                "gift-list text-uppercase font-weight-bold default--text my-3"
            },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          )
        : _vm._e(),
      _vm._l(_vm.giftList, function(gift) {
        return _c("GiftCard", {
          key: gift.userGiftCertificateId,
          staticClass: "mb-4",
          attrs: { giftCertificate: gift, mode: _vm.mode },
          on: {
            add: function($event) {
              return _vm.$emit("reload")
            }
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }